.materialListRoot {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.paginationContainer {
  margin: 0 auto 0.25em auto;
  width: 15em;
}

.paginationRoot {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 12px;
}

.paginationPage {
  padding: 0.2em 0.5em;
  margin: 0.2em;
  border: 1px solid lightgray;
  border-radius: 0.25em;
  cursor: pointer;
}

.paginationPageActive {
  padding: 0.2em 0.5em;
  margin: 0.25em;
  border: 1px solid lightgray;
  border-radius: 0.25em;
  cursor: pointer;
  background-color: #337ab7;
  color: white;
}