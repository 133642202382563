.dropdownRoot {
  display: inline-block;
  cursor: pointer;
  width: 100%;
}

.dropdownContent {
  display: none;
  position: absolute;
  min-width: 160px;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 0;
  z-index: 1;
}

.dropdownCurrent {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.dropdownMark {
  margin-left: auto;
}

.dropdownEl {
  padding: 0.25em 0.5em;
}

.dropdownEl:hover{
  background-color: #f9f9f9;
}

.dropdownRoot:hover .dropdownContent {
  display: block;
}