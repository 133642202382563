.controlPanelRoot {
  display: flex;
  flex-direction: column;
  font-size: 0.65em;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  align-items: flex-start;
  padding: 1em;
}

@media only screen and (max-width: 1200px) {
  .controlPanelRoot {
    font-size: 11px;
  }
}

.filterRoot {
  text-align: left;
}

.filterRow {
  display: flex;
  flex-direction: row;
  margin: 0.2em;
}

.settingLabel {
  margin-right: 1em;
  min-width: 8em;
}

.filterDropdown {
  min-width: 19.5em;
}

.settingRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5em;
}

.datePick {
  width: 7em;
  font-size: small;
}

.dateGroup {
  margin-right: 1em;
}