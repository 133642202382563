.materialViewContainer {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  width: 9em;
  height: 12em;
  display: flex;
  margin: 0.5em;
  cursor: pointer;
}

.materialViewContainer:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.materialViewRoot {
  width: 100%;
}

.materialViewInner {
  height: 100%;
}

.materialThumbnail {
  width: 110px;
  height: 110px;
  margin: 0.5em auto;
}

.materialTitle {
  text-align: center;
  margin: 0.5em 0.5em 0 0.5em;
  font-size: 0.65em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.materialType {
  text-align: center;
  margin: 0.5em 0.5em 0 0.5em;
  font-size: 0.5em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}