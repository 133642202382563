.cbContainer {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .cbLabel {
    padding-left: 0.2em;
    margin-bottom: 0.2em;
  }