

canvas {
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.App {
  text-align: left;
}

.mainContainer {
  display: flex;
  flex-direction: row-reverse;
  width: 75vw;
  margin: 0 auto;
}

@media only screen and (max-width: 1200px) {
  .mainContainer {
    flex-direction: column;
    width: 100%;
  }
}

.materialsContainer {
  margin: 0 auto;
  width: 85vw;
}

@media only screen and (max-width: 1200px) {
  .materialsContainer {
    width: 100%;
  }
}

.controlPanelContainer {
  margin: 1em;
  width: 35%;
}

@media only screen and (max-width: 1200px) {
  .controlPanelContainer {
    width: 90%;
    margin: 1em auto;
  }
}

.canvasContainer {
  height: 50vh;
  margin: 1em auto;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  width: 65%;
}

@media only screen and (max-width: 1200px) {
  .canvasContainer {
    width: 90%;
  }
}

.loader {
  position: relative;
  background: transparent;
  shape-rendering: auto;
  left: 50%;
  top: -50%;
  transform: translateX(-50%) translateY(-50%);
}

.mainCanvas {
  width: 100%;
  height: 100%;
}